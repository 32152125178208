<template>
  <div class="cart-contain container px-0">
    <div class="row mt-4" v-if="shipMethods.length > 0" :class="width != 'sm' ? ' mx-9' : ['', 'mb-8']">
      <div class="col-12 col-lg-8 mb-3 px-0">
        <div class="card-sec row mx-0 px-lg-3" :class="width == 'sm' ? 'p-1' : ''">
          <div class="col-12" v-if="!dineInRoute">
            <div class="pro-header mb-4 required" :class="t_ar"> {{ $t("chooseShipping") }} </div>
            <div class="col-12 px-0" :class="t_ar">
              <div class="form-group" v-for="method in shipMethods" :key="method.id">
                <div v-if="
                  $route.name == 'CheckOut' ||
                  (dineInRoute && method.id == 4) ||
                  (attachOrderRoute && method.id != 4)
                ">
                  <div class="d-flex align-items-center mb-2">
                    <input required type="radio" :id="method.id" :value="method" v-model="shipping" />
                    <label :for="method.id" class="opt-label m-0 mx-1">{{ method.name }}</label>
                  </div>
                  <div class="px-1 my-2">
                    <div class="opt-label"> - {{ $t("price") }} <span :class="f_ar_reverse">€ {{ method.delivery_price
                    }}</span>
                    </div>
                    <div class="opt-label"> - {{ $t("orderTime") }} <span :class="f_ar_reverse"> {{ method.delivery_time
                        || 0
                    }} {{ method.deliveryTimeType || "" }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" :class="t_ar" v-if="!dineInRoute && shipping.id != 4">
            <div class="form-group my-2">
              <div class="pro-header mb-4">
                <span v-if="shipping.id !== 1">{{ $t("enterLocation") }}</span>
                <button class="btn-def required" :class="[f_ar_reverse, !address.added ? 'missing-data' : '']"
                  @click="openModal"> {{ shipping.id !== 1 ? "Change Address" : "Picker Details" }} </button>
              </div>
              <div class="mb-4" :class="width != 'sm' ? 'd-flex justify-content-around' : ''"
                v-if="address.added && shipping.id !== 1">
                <div class="data-card opt-label">
                  <div class="data-label">{{ $t("col_street") }}</div> {{ updatedAddress.street }}
                </div>
                <div class="data-card opt-label">
                  <div class="data-label">{{ $t("col_area") }}</div> {{ updatedAddress.state }}
                </div>
                <div class="data-card opt-label">
                  <div class="data-label">{{ $t("col_houseNumber") }}</div> {{ updatedAddress.houseNo }}
                </div>
                <div class="data-card opt-label">
                  <div class="data-label">{{ $t("col_postalcode") }}</div> {{ updatedAddress.postalCode }}
                </div>
              </div>
              <div class="mb-4" v-else-if="address.added && shipping.id == 1">
                <div class="data-card opt-label">
                  <div class="data-label">{{ $t("col_name") }}</div> {{ updatedAddress.name }}
                </div>
                <div class="data-card opt-label">
                  <div class="data-label">{{ $t("col_phone") }}</div> {{ updatedAddress.phone }}
                </div>
              </div>
              <div v-else-if="shipping.id !== 1" class="opt-label px-1"> {{ $t("addAddress") }} !!! </div>
            </div>
          </div>
          <div class="col-12 card-container my-2" v-else-if="!dineInRoute && shipping.id == 4">
            <!-- {{this.$route.params}} -->
            <Reservation v-if="hideReservation != true" />
            <NoPlaces v-else :msg="reserveMsg" />
          </div>
          <div class="col-12" v-if="shipping.id !== 1 && shipping.id !== 4 && !dineInRoute" :class="t_ar">
            <div class="pro-header my-4 required"> {{ $t("choosePayment") }} </div>
            <div class="">
              <div class="form-group" v-for="method in payMethods" :key="method.id">
                <div class="d-flex align-items-center">
                  <input type="radio" :id="method" :value="method" v-model="payment" />
                  <label :for="method" class="opt-label m-0 mx-1">{{ payText(method) }}</label>
                </div>
                <span class="list-sub px-4 text-error" v-if="
                  method == 'paypal' &&
                  payment == 'paypal' &&
                  credintials_missing == true
                ">{{ $t("NotAvailable") }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 form-group my-2" :class="t_ar" v-if="!dineInRoute">
            <label class="pro-header mb-2">{{ $t("CouponCode") }}</label>
            <input :readonly="loading" @change="validateUserCoupon" type="text" v-model="coupon"
              class="text-input-checkout" :class="[couponValide == false ? 'missing-data' : '', t_ar]"
              :placeholder="$t('CouponCode')" />
            <span class="redeem-text mouse " :class="[
              couponValide === false ? 'text-error' : '',
              $i18n.locale == 'ar' ? 'redeem-text-ar' : '',
              couponValide == true ? 'text-success' : '',
            ]"> {{ redeemText() }} </span>
          </div>
          <div class="col-12 form-group my-2" :class="t_ar">
            <label class="pro-header mb-2">{{ $t("specialRequest") }}</label>
            <input type="text" class="text-input-checkout" :class="t_ar" v-model="comment"
              :placeholder="$t('specialRequest')" />
          </div>
          <div class="col-12 mt-2" v-if="width != 'sm'">
            <div class="divider"></div>
            <button class="btn-pri-lg btn-block" :disabled="loading" :class="[t_ar, loading ? 'btn-secondary' : '']"
              @click="placeOrder"> {{ $t("placeOrder") }} <i class="fa" v-if="!loading" :class="
                $i18n.locale == 'ar'
                  ? ['fa-chevron-left', 'f-left']
                  : ['fa-chevron-right', 'f-right']
              "></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 px-0 px-lg-3 col-lg-4" :class="width == 'sm' ? 'mt-3' : ''">
        <div class="card-sec row mx-0 px-0" :class="width == 'sm' ? 'p-2' : ''">
          <div class="col-12 " :class="t_ar">
            <div class="pro-header mb-4"> {{ $t("summary") }} </div>
            <div class="opt-label"> {{ $t("TotalWithoutTax") }} <span :class="f_ar_reverse"> {{
                subTotalWithNoTax.toFixed(2)
            }} €</span>
            </div>
            <div class="opt-label"> {{ $t("VAT") }} <span :class="f_ar_reverse">{{ VAT.toFixed(2) }} €</span>
            </div>
            <div class="opt-label"> {{ $t("subTotal") }} <span :class="f_ar_reverse">{{ subTotal.toFixed(2) }} €</span>
            </div>
            <div class="opt-label mt-3"> {{ $t("delivery") }} <span :class="f_ar_reverse">{{ shipping.delivery_price ||
                0
            }} €</span>
            </div>
            <div class="opt-label"> {{ $t("CouponValue") }} <span :class="f_ar_reverse">{{ couponValue.toFixed(2) || 0
            }} €</span>
            </div>
            <div class="opt-name text-center total-card mt-4"> {{ $t("total") }} {{ total }} € </div>
          </div>
          <div class="col-12 mt-3 px-0" v-if="width == 'sm'">
            <button class="btn-pri-lg m-0 btn-block py-2" :disabled="loading"
              :class="[t_ar, loading ? 'btn-secondary' : '']" @click="placeOrder"> {{ $t("placeOrder") }} <i class="fa"
                v-if="!loading" :class="
                  $i18n.locale == 'ar'
                    ? ['fa-chevron-left', 'f-left']
                    : ['fa-chevron-right', 'f-right']
                "></i>
            </button>
          </div>
        </div>
      </div>
      <div class="modal fade" id="checoutModal">
        <div class=" modal-dialog" :class="width == 'sm' ? 'w-100' : ['modal-lg', 'mx-auto']">
          <div class="modal-content filter-contain" style="width: 96% ">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <i class="fa fa-times"></i>
              </button>
            </div>
            <div class="modal-body p-0">
              <div class="container">
                <AddressForm :addAddress="addAddress" :ship="shipping" :focus="focus" :defaultAddress="defaultAddress"
                  :user="user" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoPlaces v-else />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AddressForm from "@/components/forms/AddressForm";
import Reservation from "@/components/order/Reservation";
import NoPlaces from "@/components/misc/NoPlaces";
import cookies from "vue-cookies";
import md5 from "md5";

export default {
  components: {
    AddressForm,
    NoPlaces,
    Reservation,
  },
  data() {
    return {
      address: {},
      defaultAddress: {},
      payment: "",
      shipping: {},
      subTotal: 0,
      subTotalWithNoTax: 0,
      availableCodes: [],
      coupon: "",
      coupon_code: "",
      couponValide: "",
      couponValue: 0,
      comment: "",
      focus: false,
      status: "Pending",
      loaded: false,
      showPaypal: false,
      returnTotal: 0,
      updateSecret: "",
      client_id: null,
      client_secret: null,
      payment_id: "",
      fees: 0,
      transaction_id: "",
      order_id: "",
      payer_id: "",
      credintials_missing: false,
      Zone: {},
      updatedAddress: {},
      hideReservation: false,
      reserveMsg: "",
    };
  },
  watch: {
    shipping: function (newShip, oldShip) {
      if (newShip.id != 1 && !this.Zone.cost) {
        if (
          this.shipping.delivery_note != "" &&
          parseFloat(this.shipping.delivery_note) != 0
        ) {
          if (this.subTotal > parseFloat(this.shipping.delivery_note)) {
            this.shipping.delivery_price = 0;
          }
        }
      }
      if (oldShip.id == 1 && oldShip !== newShip) {
        this.address.added = false;
      }

      this.payment =
        this.payMethods && newShip && newShip.id > 1 ? this.payMethods[0] : "";
      this.$forceUpdate();
    },
    shipMethods: function (data, old) {
      if (data !== old && data.length > 0) {
        if (this.dineInRoute) {
          this.shipping = data.find((el) => el.id == 4);
        } else this.shipping = data[0];
        this.$forceUpdate();
      }
    },
    // payment: function(newPay, oldPay) {
    //   if(newPay == 'paypal' && newPay != oldPay) {
    //     this.showPaypal = true;
    //   }else{
    //     this.showPaypal = false
    //   }
    // },
    client_id: function (newClient, lodClient) {
      // this.loadPaypalScript();
      // console.log(this.client_id)
    },
    reserveResults: function (newReserve, oldReserve) {
      if (newReserve !== oldReserve) {
        this.hideReservation = true;
        this.reserveMsg = `Hall Name: ${this.reserveResults.hall.name
          }, table: ${JSON.stringify(this.reserveResults.numbers)}`;
      }
    },
  },
  mounted() {
    this.getBookingDetails({
      lang: this.$i18n.locale,
      business_id: cookies.get("BusId"),
    });
    let products = this.cartItems.map((el) => {
      if (el.options != undefined) {
        return {
          product_id: el.product_id,
          options: Object.values(el.options).map((opt) => opt.id),
        };
      } else {
        return {
          product_id: el.product_id,
          options: [],
        };
      }
    });
    let quantity = this.cartItems.map((el) => parseInt(el.quantity));
    let price = this.cartItems.map((el, index) => {
      if (el.options != undefined) {
        let total = 0;
        for (const [key, value] of Object.entries(el.options)) {
          total += parseInt(value.price);
        }
        this.subTotal += total * quantity[index];
        return {
          product_price: total,
          options_price: Object.values(el.options).map((opt) => opt.price),
        };
      } else {
        this.subTotal += parseFloat(el.price) * quantity[index];
        return {
          product_price: el.price,
          options_price: el.price,
        };
      }
    });
    let price_no_tax = this.cartItems.map((el, index) => {
      if (el.options != undefined) {
        let total = 0;
        for (const [key, value] of Object.entries(el.options)) {
          total += parseInt(value.price);
        }
        if (el.tax != 0) {
          total = total / (el.tax / 100 + 1);
        }
        this.subTotalWithNoTax += total * quantity[index];
      } else {
        let elPrice = el.price;
        if (el.tax != 0) {
          elPrice = el.price / (el.tax / 100 + 1);
        }
        this.subTotalWithNoTax += parseFloat(elPrice) * quantity[index];
      }
    });
    this.addToCart({
      products,
      quantity,
      price,
      lang: this.$i18n.locale,
    }).then((res) => {
      if (this.postCodes.length > 0) {
        this.postCodes.forEach((el) => {
          for (let code = el.from; code <= el.to; code++) {
            this.availableCodes.push(code);
          }
        });
      }
    });
  },
  methods: {
    ...mapActions([
      "addToCart",
      "checkOutOrder",
      "getAllCartItems",
      "checkPhone",
      "createAddress",
      "updateAddress",
      "getToken",
      "createOrder",
      "captureOrder",
      "approveOrder",
      "updatePayment",
      "getBookingDetails",
      "createPayment",
      "executePayment",
      "executeRequest",
      "validateCoupon",
      "attackOrderToReservation",
      "getUserAddresses",
    ]),
    ...mapMutations(["showMsg", "destroyVCart"]),
    payText(text) {
      if (text == "cod") {
        return "Cash On Delivery";
      } else if (text == "pod") {
        return "Payment On Delivery";
      } else if (text == "paypal") {
        return "Paypal";
      }
    },
    openModal() {
      if (cookies.isKey("defaultAddressId")) {
        this.getUserAddresses(this.user.api_token).then((res) => {
          this.defaultAddress = this.userAddresses.find(
            (el) => el.id == cookies.get("defaultAddressId")
          );
        });
      }
      $("#checoutModal").modal({ backdrop: "static" });
    },
    addAddress(address) {
      if (
        this.availableCodes.length > 0 &&
        address.postalCode &&
        this.shipping.id != 1
      ) {
        let myPostalCode = this.availableCodes.find(
          (el) => el == address.postalCode
        );
        if (this.availableCodes.find((el) => el == address.postalCode)) {
          this.Zone = this.postCodes.find(
            (el) => myPostalCode >= el.from && myPostalCode <= el.to
          );
          if (this.Zone.minOrder > 0 && this.subTotal < this.Zone.minOrder) {
            this.showMsg({
              text: this.$t("minOrderError", {
                postalCode: address.postalCode,
                min: this.Zone.minOrder,
              }),
              type: "error",
              clsoe: true,
              condition: 2,
            });
          } else {
            this.phoneValidate(address);
            this.shipping.delivery_time = this.Zone.deliveryTime;
            this.shipping.deliveryTimeType = this.Zone.deliveryTimeType;
            if (this.Zone.cost == 0) {
              this.shipping.delivery_price = 0;
            } else if (this.Zone.shippingFreeAfter == 0) {
              this.shipping.delivery_price = this.Zone.cost;
            } else {
              if (this.subTotal > this.Zone.shippingFreeAfter) {
                this.shipping.delivery_price = 0;
              } else {
                this.shipping.delivery_price = this.Zone.cost;
              }
            }
          }
        } else {
          // console.log(this.availableCodes)
          this.showMsg({
            text: this.$t("deliveryError", { postalCode: address.postalCode }),
            type: "error",
            clsoe: true,
            condition: 2,
          });
        }
      } else {
        this.phoneValidate(address);
      }
    },
    placeOrder() {
      var user_token = this.loggedIn
        ? cookies.get("user_token")
        : cookies.get("guest");
      if (this.shipping.id == 1) {
        this.payment = this.payMethods[0];
      }
      if (this.dineInRoute == true || this.shipping.id == 4) {
        if (!this.reserveResults && !this.reserveResults.numbers) {
          this.showMsg({
            text: this.$t("CompleteTheReservationFirst"),
            type: "error",
          });
        }
        if (this.dineInRoute) {
          this.reserveMsg = `Hall Name: ${this.$route.params.hallName}, table: ${this.$route.params.tableId}`;
        }
        this.checkOutOrder({
          total: this.total,
          tax: this.VAT,
          shipping: this.shipping.id,
          comment: this.comment,
          api_token: user_token,
          payment: "cod",
          table_number:
            this.$route.name == "CheckOut"
              ? this.reserveResults.numbers[0]
              : this.reserveMsg,
          hall_id: this.reserveResults.hall_id,
        })
          .then((res) => {
            let data = res.data.data;
            this.showMsg({
              text: this.$t("orderSuccessfulRedirect"),
              type: "success",
            });
            this.destroyVCart();
            this.getAllCartItems();
            setTimeout(() => {
              let routeName = this.loggedIn ? "OrderHistory" : "MyOrders";
              this.$router.push({
                name: routeName,
                params: {
                  id: cookies.get("hold"),
                },
              });
              // if(this.$route.name == 'DineIn') {
              //   this.$router.push({
              //     name: 'Home',
              //   });
              // }else {
              // this.$router.push({
              //   name: 'Reservation',
              //   params: {
              //     id: data.business.id,
              //     orderId: data.id,
              //   }
              // });
              // }
            }, 2000);
          })
          .catch((err) => {
            this.showMsg({
              text: this.$t("methodError"),
              type: "success",
            });
          });
      } else if (
        Object.entries(this.shipping).length > 0 &&
        this.payment != "" &&
        Object.entries(this.address).length > 0 &&
        this.address.added == true
      ) {
        this.checkOutOrder({
          address: this.address.street + "," + this.address.state,
          address_id: cookies.get("addressId"),
          total: this.total,
          tax: this.VAT,
          payment: this.payment,
          shipping: this.shipping.id,
          deliveryCost: this.shipping.delivery_price,
          name: this.address.name,
          phone: this.address.phone,
          postal_code: this.address.postalCode,
          street: this.address.street,
          houseNo: this.address.houseNo,
          coupon_code: this.coupon_code,
          comment: this.comment,
          api_token: user_token,
        })
          .then((res) => {
            let data = res.data.data;
            if (
              data.business.paypal_client_id != null &&
              data.business.paypal_secret != null
            ) {
              this.client_id = data.business.paypal_client_id;
              this.client_secret = data.business.paypal_secret;
            } else {
              this.credintials_missing = true;
            }
            if (this.$route.name == "AttachOrder") {
              this.attackOrderToReservation({
                api_token: user_token,
                reservation_id: this.$route.params.reservation_id,
                order_id: data.id,
              })
                .then((res) => {
                  this.showMsg({
                    text: this.$t("OrderAttachSuccessful"),
                    type: "success",
                  });
                })
                .catch((err) => {
                  this.showMsg({
                    text: this.$t("OrderAttachError"),
                    type: "error",
                  });
                });
            }
            this.order_id = data.id;
            this.returnTotal = data.amount;
            this.payment_id = data.payment_id;
            this.updateSecret = this.makeSecret(
              data.created_at,
              data.id,
              data.payment_id,
              data.business.id
            );
            this.destroyVCart();
            this.getAllCartItems();
            this.$store.state.loading = true;
            if (this.payment == "paypal") {
              if (this.credintials_missing == false) {
                this.showMsg({
                  text: this.$t("orderSuccessfulRedirect"),
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.push({
                    name: "Payment",
                    params: {
                      client_id: this.client_id,
                      client_secret: this.client_secret,
                      updateSecret: this.updateSecret,
                      order_id: this.order_id,
                      payment_id: this.payment_id,
                      total: this.total,

                      subTotalWithNoTax: this.subTotalWithNoTax,
                      VAT: this.VAT,
                      subTotal: this.subTotal,
                      delivery_price: this.shipping.delivery_price,
                      couponValue: this.couponValue,
                    },
                  });
                }, 2000);
              } else {
                this.showMsg({
                  text: this.$t("PaypalNotAvaialble"),
                  type: "error",
                });
              }
            } else {
              this.showMsg({
                text: this.$t("orderSuccessful"),
                type: "success",
              });
              setTimeout(() => {
                let routeName = this.loggedIn ? "OrderHistory" : "MyOrders";
                this.$router.push({
                  name: routeName,
                  params: {
                    id: cookies.get("hold"),
                  },
                });
              }, 3000);
            }
          })
          .catch((err) => {
            this.showMsg({
              text: this.$t("methodError"),
              type: "error",
            });
          });
      } else {
        this.showMsg({
          text: this.$t("methodError"),
          type: "error",
        });
        throw new Error(this.$t("methodError"));
      }
    },
    phoneValidate(address) {
      // this.checkPhone(address.phone)
      //   .then(res => {
      this.focus = false;
      this.address = address;
      this.address.added = true;
      $(".modal").modal("hide");

      if (cookies.isKey("addressId")) {
        this.updateAddress({
          address: address.street + "," + address.state,
          address_id: cookies.get("addressId"),
          postal_code: address.postalCode,
          street_name: address.street,
          apartment_no: address.houseNo,
          phone: address.phone,
        }).then((res) => {
          this.updatedAddress = { ...address };
        });
      } else {
        this.createAddress({
          address: address.street + "," + address.state,
          postal_code: address.postalCode,
          street_name: address.street,
          apartment_no: address.houseNo,
          phone: address.phone,
        }).then((res) => {
          this.updatedAddress = { ...address };
        });
      }
      // }).catch(err => {
      //   this.showMsg({
      //     text: this.$t('notVaildNumber'),
      //     type: 'error'
      //   });
      //   this.focus = true;
      // })
    },

    makeSecret(created, id, pay, bus) {
      return md5(`${created}|${id}|${pay}|${bus}`);
    },
    validateUserCoupon() {
      // console.log(cookies.get('BusId'), this.coupon, 'heeerere')
      this.validateCoupon({
        business_id: cookies.get("BusId"),
        coupon_code: this.coupon,
      })
        .then((res) => {
          let data = res.data.data;
          this.coupon_code = this.coupon;
          this.couponValide = true;
          if (data.type == "fixed") {
            this.couponValue = data.value;
          } else if (data.type == "percentage") {
            this.couponValue = (parseFloat(this.subTotal) * data.value) / 100;
          }
        })
        .catch((err) => {
          this.couponValue = 0;
          this.couponValide = false;

          this.showMsg({
            type: "error",
            text: this.$t("CouponError"),
          });
        });
    },
    redeemText() {
      if (this.couponValide == true) {
        return "Redeemed";
      } else if (this.couponValide == "") {
        return "Redeem";
      } else if (this.couponValide === false) {
        return "Not Valide";
      }
    },
  },
  computed: {
    ...mapGetters([
      "cartItems",
      "shipMethods",
      "payMethods",
      "width",
      "postCodes",
      "loading",
      "loggedIn",
      "user",
      "userAddresses",
      "reserveResults",
    ]),
    total() {
      return (
        this.subTotal +
        (this.shipping.delivery_price || 0) -
        this.couponValue
      ).toFixed(2) < 0
        ? 0
        : (
          this.subTotal +
          (this.shipping.delivery_price || 0) -
          this.couponValue
        ).toFixed(2);
    },
    VAT() {
      return this.subTotal - this.subTotalWithNoTax;
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    attachOrderRoute() {
      return this.$route.name == "AttachOrder";
    },
    dineInRoute() {
      return this.$route.name == "DineIn";
    },
  },
};
</script>
