<template>
  <div class="text-center">
    <div class=" my-3  align-self-center"
      :class="[item.status == 1 ? 'pointer shadow-eff serv-body' : 'mouse serv-body-disabled', chosen == item.id ? 'serv-body-chosen' : '']"
      @click='serviceClicked(item)'>
      <div class=" privacy-color" :class="width == 'sm' ? 'data-label' : 'opt-name'"> {{ item.name }} </div>
      <div class="text-ellipsis privacy-color" :class="width == 'sm' ? 'smaller-get' : ''"> {{ item.description }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {

  props: ['serviceChoose', 'item', 'chosen'],
  mounted() {

  },
  methods: {
    ...mapActions(['getAvailableBlocks']),
    serviceClicked(item) {
      this.serviceChoose(item);
    }
  },
  computed: {
    ...mapGetters(['width']),
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right' : 'text-left';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse' : '';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right' : 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left' : 'f-right';
    },
  }
}
</script>