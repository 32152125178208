<template>
  <div class="container px-0">
    <div class="row justify-content-center ">
      <div class="col-12 px-0" v-if="business && business.times && business.times.length > 0">
        <!-- service choose section -->
        <div class="row">
          <div class="main-title w-100" :class="[t_ar, $i18n.locale == 'ar' ? 'text-right' : 'text-left']"> {{
              $t("ChooseService")
          }} </div>
          <div class="col-12 px-0" v-if="business.times && services && services.length > 0">
            <Swiper class="swiper" ref="ServicesSwiper" :options="swiperOptions"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <SwiperSlide class="col-auto px-1" v-for="item in services" :key="item.id">
                <div>
                  <ServicesBtns :item="item" :serviceChoose="serviceChoose" :chosen="selectedData.service_id" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <NoPlaces v-else :msg="$t('NoServicesForthisBus')" />
        </div>
        <!-- halls view section  -->
        <div class="row m-0">
          <h3 class="main-title my-3 w-100" :class="[t_ar, $i18n.locale == 'ar' ? 'text-right' : 'text-left']">{{
              $t("Halls")
          }} </h3>
          <div class="col-12 mb-2" :class="[t_ar]" v-if="
            business &&
            business.halls &&
            business.halls.length > 0 &&
            serviceHalls &&
            serviceHalls.length > 0
          " id="hallsarea">
            <div :class="flex_ar">
              <button class="my-3 mx-2 px-2 pointer" v-for="(hall, i) in serviceHalls" :key="i"
                @click="selectHall(hall)" :class="
                  selectedData.hall_id == hall.id
                    ? 'btn-pri disabled'
                    : 'btn-hall'
                "> {{ hall.name || "Hall #" + hall.id }} </button>
            </div>
          </div>
          <NoPlaces v-else-if="!selectedData.service_id" :msg="$t('pleaseChooseService')" />
          <NoPlaces v-else :msg="$t('NoHallsForthisservice')" />
        </div>
        <!-- time / date form section  -->
        <div class="row ">
          <div class="col-12 px-0">
            <div class="main-title" :class="t_ar"> {{ $t("Choose Time") }} </div>
            <div class="row main-card px-0" :class="flex_ar" v-if="
              enabledTimes && selectedData.hall_id && selectedData.service_id
            ">
              <div v-if="enabledDates && enabledDates.length > 0" class="col-md-12 col-12 mb-3 px-0 m-0">
                <Swiper class="swiper px-1" ref="datesSwiper" :options="swiperOptions"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                  <SwiperSlide class="col-auto px-1" v-for="(item, ind) in enabledDates" :key="ind">
                    <div>
                      <DatesBtns :item="item" :dateChoose="dateChoose" :chosen="selectedData.from_date" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <NoPlaces v-else :msg="$t('NoTimesForThisService')" />
              <div class="col-12" v-if="
                enabledTimes &&
                enabledTimes.length > 0 &&
                spent_time &&
                enabledDates &&
                enabledDates.length > 0
              ">
                <select @change="timeChoose" v-model="selectedData.time" required
                  class="form-control text-input-sm border">
                  <option :value="null">{{ $t("selectTime") }}</option>
                  <option v-for="(time, i) in enabledTimes" :key="i" :value="time"> {{ time }} </option>
                </select>
              </div>
              <div class="col-12 mt-3 text-center " @click="searchAvailable()">
                <button class="btn-pri w-50" :disabled="
                  loading ||
                  (enabledTimes &&
                    enabledTimes.length > 0 &&
                    !selectedData.time) ||
                  !selectedData.from_date ||
                  (enabledDates && enabledDates.length > 0)
                ">
                  <i class="fa" :class="loading ? 'fa-spin fa-spinner' : 'fa-search'"></i> {{ $t("getPlaces") }}
                </button>
                <div class="my-2" v-if="enabledDates.length > 0 && !selectedData.from_date">
                  <i class="text-warning fa fa-warning" /> {{ $t("PleaseChooseDate") }}
                </div>
                <div class="my-2" v-if="
                  enabledTimes &&
                  enabledTimes.length > 0 &&
                  !selectedData.time
                ">
                  <i class="text-warning fa fa-warning" /> {{ $t("PleaseChooseTime") }}
                </div>
              </div>
            </div>
            <NoPlaces v-else-if="!selectedData.hall_id || !selectedData.service_id"
              :msg="$t('pleaseChooseHallAndService')" />
            <NoPlaces v-else :msg="$t('NoTimesForThisService')" />
          </div>
        </div>
        <!-- MYRESID -->
        <div class="row mt-3" v-if="showAttendersForm == true">
          <div class="col-12 px-0">
            <div class="main-title " :class="t_ar"> {{ $t("chooseNoAttendees") }} </div>
            <div class="row main-card " :class="flex_ar" v-if="
              selectedData.service_id &&
              selectedData.hall_id &&
              availableBlocks &&
              availableBlocks > 0
            ">
              <div class="main-title mb-3 px-0 col-12" :class="t_ar"> {{ $t("AvailablePlaces") }} ({{ availableBlocks
              }}) </div>
              <div class="col-12 px-0">
                <div class="form-group text-center px-0 m-0">
                  <button class="btn-icon-def  m-0 p-0" @click="increaseQuantity">
                    <i class="fa fa-plus"></i>
                  </button>
                  <input :class="t_ar" type="number" v-model="selectedData.attendeesNO" min="1"
                    :max="maxAvailableBlocks" class="text-input-sm mx-md-3 mx-1 col-4"
                    placeholder="Please put the Number of Attendees?" @change="checkAttendeesNo()" />
                  <button class="btn-icon-def p-0 m-0" @click="decreaseQuantity">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
            </div>
            <NoPlaces v-else-if="
              availableBlocks &&
              availableBlocks == 0 &&
              showAttendersForm == true
            " :msg="$t('noAvailablePlaces')" />
            <NoPlaces v-else />
          </div>
        </div>
        <div class="row mt-3" v-if="payObj.price && showAttendersForm == true">
          <div class="col-12 px-0">
            <div class="main-title " :class="t_ar"> {{ $t("payment") }} </div>
            <div class="main-card" :class="flex_ar">
              <PayForm :getUserPay="getUserPay" :payObj="payObj" />
            </div>
          </div>
        </div>
        <!-- Submit form reservation -->
        <div class="row mt-3" v-if="showAttendersForm == true">
          <div class="col-12 px-0">
            <div class="main-title mb-3 body" id="AttendeesInfoBox" :class="[t_ar]"> {{ $t("AttendeesInfo") }} <span
                class="badge badge-warning" v-if="
                  !loading && (selectedData.time || selectedData.attendeesNO)
                "> {{ Object.values(selectedData.attenders).length }} / {{ selectedData.attendeesNO }} </span>
            </div>
            <div class="" v-if="!selectedData.attendeesNO" id="noRoomForBooking">
              <div class="alert alert-warning">
                <i class="fa fa-times-circle fa-2x"></i> {{ $t("noRoomForBooking") }} {{ $t("yourGoingToBeOnHold") }}
              </div>
            </div>
            <form :class="flex_ar" class="row justify-content-center main-card" @submit.prevent="saveAttends()"
              v-if="selectedForm" id="saveAttends">
              <div v-for="(col, key, i) in columns" :key="i" :class="[`col-md-${col.class}`, 'px-0 px-md-2']">
                <label :class="
                  (
                    col.required &&
                      (col.required == 'one' ||
                        col.required == 'reservation-one') &&
                      (!selectedData.attenders[firstOne] ||
                        !selectedData.attenders[firstOne][key])
                      ? true
                      : col.required === true || null
                  )
                    ? ['required', 'input-label', f_ar]
                    : ['input-label', f_ar]
                ">{{ $t("col_" + key) }}</label>
                <input v-if="!col.mask" :type="col.type" @blur="validateData(col, key)" :id="'col_' + key"
                  :class="{ 'is-invalid': col.error, 'is-valid': col.valid }" :readonly="loading" :required="
                    col.required &&
                      (col.required == 'one' ||
                        col.required == 'reservation-one') &&
                      (!selectedData.attenders[firstOne] ||
                        !selectedData.attenders[firstOne][key])
                      ? true
                      : col.required === true || null
                  " class="form-control text-input-sm border" :placeholder="$t('col_' + key)"
                  v-model="selectedForm[key]" />
                <input v-else :type="col.type" @blur="validateData(col, key)" :id="'col_' + key"
                  :class="{ 'is-invalid': col.error, 'is-valid': col.valid }" :readonly="loading" v-mask="col.mask"
                  :masked="true" :required="
                    col.required &&
                      col.required == 'one' &&
                      (!selectedData.attenders[firstOne] ||
                        !selectedData.attenders[firstOne][key])
                      ? true
                      : col.required === true || null
                  " class="form-control text-input-sm border" :placeholder="$t('col_' + key)"
                  v-model="selectedForm[key]" />
                <small class="text-danger" v-if="col.error"> {{ col.error }} </small>
              </div>
              <div class="col-12 text-left mt-3">
                <button class="btn btn-lg btn-success" type="submit"> {{ $t("saveAttender") }} </button>
                <a class="btn btn-light float-right mt-3" v-if="$root.$len(selectedData.attenders) > 0"
                  @click="cancelAttender()"> {{ $t("Cancel") }} </a>
              </div>
            </form>
            <form v-else class="contact-form row text-center main-card" @submit.prevent="submit()">
              <div class="col-12 py-3">
                <button type="button" :disabled="
                  loading ||
                  selectedData.attendeesNO -
                  $root.$len(selectedData.attenders) <=
                  0
                " class="btn btn-outline-success mb-2" @click="attenderForm()">
                  <i class="fa fa-plus-circle"></i> {{ $t("addAttender") }} <span class="text-warning" v-if="
                    selectedData.attendeesNO -
                    $root.$len(selectedData.attenders) >=
                    0
                  "> ({{ selectedData.attendeesNO - $root.$len(selectedData.attenders) }}) </span>
                </button>
              </div>
              <div class="col-12">
                <ul class="list-group pb-3 radius-1">
                  <li class="list-group-item list-group-item-action" v-for="(rs, id, i) in selectedData.attenders"
                    :key="i">
                    <div class="row listing-attendee">
                      <div class="col-sm-4">
                        <i class="fa fa-user"></i> {{ rs.name }}
                      </div>
                      <div class="col-sm-4">
                        <i v-if="rs.phone" class="fa fa-phone"></i> {{ rs.phone || "" }}
                      </div>
                      <div class="col-sm-2" v-if="!loading">
                        <i class="fa fa-pencil pointer px-1" @click="attenderForm(id)"></i> &nbsp;&nbsp; <i
                          v-if="$root.$len(selectedData.attenders) > 1 && i > 0" @click="removeAttender(id)"
                          class="fa fa-trash pointer text-danger px-1"></i>
                      </div>
                      <div class="col-sm-2" v-else>
                        <i class="fa fa-spin fa-spinner"></i>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="form-group col-12">
                <div class="form-check">
                  <input type="checkbox" class="inlineBlock" required /> &nbsp; {{ $t("agreeConditions") }}&nbsp; <a
                    class="pointer" @click="openModal"> {{ $t("conditions") }} </a>
                </div>
              </div>
              <div class="form-group col-12">
                <input type="submit" class="btn btn-success btn-lg" :disabled="loading || profileLoading"
                  :value="$t('submit')" />
              </div>
            </form>
          </div>
        </div>
        <!-- Close container -->
      </div>
    </div>
    <div class="modal pb-9" id="termsCond" tabindex="-1" aria-labelledby="termsCondLabel" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog" v-if="
        business &&
        selectedData.hall_id != '' &&
        selectedData.attendeesNO >= 1
      " :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title modal-header-custom text-center" id="termsCondLabel"> {{ $t("conditions") }} ({{
                business.name
            }}) </h3>
            <a type="button" class="close" @click="closeModal" aria-label="Close">
              <i class="fa fa-times"></i>
            </a>
          </div>
          <div class="modal-body" :class="t_ar">
            <ul class="list-group">
              <li class="list-group-item"> {{ $t("over12") }} </li>
              <li class="list-group-item"> {{ $t("noDiseases") }} </li>
              <li class="list-group-item"> {{ $t("wearmask") }} </li>
              <li class="list-group-item"> {{ $t("respectSefety") }} </li>
              <li class="list-group-item"> {{ $t("followSupervisors") }} </li>
              <li class="list-group-item" v-if="business.category.id == 14"> {{ $t("performAblution") }} </li>
              <li class="list-group-item" v-if="business.category.id == 14"> {{ $t("bringCarpet") }} </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" @click="closeModal" class="btn btn-secondary"> {{ $t("Close") }} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Donate from "@/components/misc/Donate";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import PayForm from "@/components/forms/PayForm.vue";
import { helpers } from "../../Helpers";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import ServicesBtns from "@/components/order/ServicesBtns.vue";
import DatesBtns from "@/components/order/DatesBtns.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import cookies from "vue-cookies";

export default {
  components: {
    // stars,
    Donate,
    ServicesBtns,
    DatesBtns,
    Swiper,
    SwiperSlide,
    PayForm,
    NoPlaces,
  },
  mixins: [helpers],
  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 0,
      },
      firstOne: null,
      columns: {
        name: { type: "text", required: true, class: "6" },
        phone: {
          type: "tel",
          required: "one",
          class: "6",
          mask: "49###############",
        },
        email: { type: "email", required: "reservation-one", class: "12" },
        street: { type: "text", required: false, class: "6" },
        houseNumber: { type: "text", required: false, class: "6" },
        postalcode: { type: "number", required: false, class: "6" },
        area: { type: "text", required: false, class: "6" },
      },
      success: false,
      print: null,
      printStatus: false,
      // types: '3,4',
      filter: {},
      title: document.title,
      selectedBusinessID: null,

      selectedData: {
        business_id: this.$route.params.id,
        time_id: null,
        hall_id: "",
        from_date: "",
        time: "",

        total_paid: "",
        attendeesNO: 1,
        attenders: {},
      },

      selectedForm: "",
      serviceHalls: [],
      payObj: {},
      enabledTimes: [],
      spent_time: "",
      enabledDates: [],
      currentDate: "",
      showAttendersForm: false,
      defaultAddress: {},
    };
  },
  watch: {
    showAttendersForm: function (newShow, oldShow) {
      if (newShow == true && this.loggedIn) {
        this.getUserAddresses(this.user.api_token).then((res) => {
          this.defaultAddress = this.userAddresses.find(
            (el) => el.id == cookies.get("defaultAddressId")
          );
        });
      }
    },
    defaultAddress: function (newAdd, oldAdd) {
      if (newAdd !== oldAdd) {
        this.selectedForm = {
          name: this.user.name,
          phone: this.user.phone || this.defaultAddress.phone,
          email: this.user.email,
          street: this.defaultAddress.street_name,
          houseNumber: this.defaultAddress.apartment_no,
          postalcode: this.defaultAddress.postal_code,
          area: this.defaultAddress.city,
        };
      }
    },
  },

  methods: {
    ...mapMutations(["showMsg"]),
    ...mapActions([
      "getAvailableBlocks",
      "reservationTimeID",
      "updateReservePay",
      "retrieveUser",
      "startLoading",
      "stopLoading",
      "getBookingDetails",
      "emptyAvailableBlocks",
      "checkPhone",
      "attackOrderToReservation",
      "getUserAddresses",
    ]),
    decreaseQuantity() {
      if (
        this.selectedData.attendeesNO - 1 != 0 &&
        this.selectedData.attendeesNO != 1
      ) {
        this.selectedData.attendeesNO--;
      }
    },
    increaseQuantity() {
      if (this.selectedData.attendeesNO + 1 <= this.maxAvailableBlocks) {
        this.selectedData.attendeesNO++;
      }
    },
    checkAttendeesNo() {
      if (
        this.selectedData.attendeesNO >= 1 &&
        this.selectedData.attendeesNO <= this.maxAvailableBlocks
      ) {
      } else {
        this.selectedData.attendeesNO = this.maxAvailableBlocks;
        this.showMsg({
          text: this.$t("attendeesNoCantExceedAvailableBlocks"),
          type: "error",
        });
      }
    },
    getUserPay(paid) {
      this.selectedData.total_paid = paid;
    },
    serviceChoose(service) {
      this.startLoading();
      this.enabledDates = [];
      this.enabledTimes = [];
      this.selectedData.hall_id = null;
      this.selectedData.from_date = null;
      this.selectedData.time = null;
      this.showAttendersForm = false;
      this.emptyAvailableBlocks();
      this.spent_time = service.spent_time || null;
      this.selectedData.service_id = service.id;
      if (service.price) {
        if (service.pay_type == "amount") {
          this.payObj = {
            pay_type: service.pay_type,
            price: service.price,
            deal_pay: service.deal_pay,
          };
        } else {
          this.payObj = {
            pay_type: service.pay_type,
            price: service.price,
            deal_pay: (service.deal_pay / 100) * service.price,
          };
        }
      } else {
        this.payObj = {};
      }
      this.buildDays(service);
      this.buildTimes(service);
      if (!this.business.halls) {
        this.business.halls = [...this.bookDetails.results];
      }
      if (service.halls_ids == null) {
        this.serviceHalls = this.business.halls;
      } else {
        this.serviceHalls = this.business.halls.filter((el) =>
          service.halls_ids.includes(el.id)
        );
      }
      this.stopLoading();
    },
    buildDays(service) {
      this.startLoading();
      this.currentDate = moment().locale("en").format("YYYY-MM-DD");
      if (service.days) {
        let max_days = service.max_days || 14;
        for (let i = 0; i < max_days / 7; i++) {
          service.days.forEach((el) => {
            if (moment().add(i, "week").day(el).isBefore(moment()) != true) {
              this.enabledDates.push(
                moment().add(i, "week").day(el).format("YYYY-MM-DD ddd")
              );
            }
          });
        }
        if (max_days % 7 > 0) {
          for (let i = 0; i < max_days % 7; i++) {
            let startDate = moment().add(max_days / 7, "week");
            if (service.days.includes(i)) {
              service.days.forEach((el) => {
                if (moment().add(i, "week").day(el).isBefore(moment()) != true) {
                  this.enabledDates.push(moment().day(i).format("YYY-MM-DD ddd"));
                }
              });
            }
          }
        }
      } else {
        service.list_dates.sort((a, b) => new Date(a) - new Date(b));
        service.list_dates.forEach((el) => {
          if (moment(el).isBefore(moment()) != true) {
            this.enabledDates.push(
              moment(el, "YYYY-MM-DD").format("YYYY-MM-DD ddd")
            );
          }
        });
      }
      this.stopLoading();
    },
    buildTimes(service) {
      var from = service.from
        ? moment(service.from, "HH:mm:ss").locale("en")
        : null;
      var to = service.to ? moment(service.to, "HH:mm:ss").locale("en") : null;
      if (this.spent_time) {
        while (true) {
          // console.log(from, to, from >= to)
          if (from >= to || !from || !to) break;
          this.enabledTimes.push(from ? from.format("HH:mm") : "");
          // console.log( from >= to)
          from.add(this.spent_time, "minutes");
        }
      }
    },
    selectHall(hall) {
      this.startLoading();
      this.selectedData.hall_id = hall.id;
      this.searchAvailable();
      this.stopLoading();
    },
    searchAvailable() {
      this.startLoading();
      let payload = {
        business_id: this.selectedData.business_id || cookies.get("BusId"),
        from_date: this.selectedData.from_date,
        hall_id: this.selectedData.hall_id,
      };
      this.spent_time && this.spent_time != null
        ? (payload = { ...payload, time: this.selectedData.time })
        : (payload = { ...payload, time_id: this.selectedData.service_id });
      if (this.selectedData.from_date) {
        this.getAvailableBlocks(payload)
          .then((res) => {
            // console.log(res);
            this.attenderForm();
            this.showAttendersForm = res.data.data > 0 ? true : false;
            if (res.data.data == 0) {
              this.showMsg({
                text: this.$t("noAvailablePlaces"),
                type: "error",
              });
            }
            this.stopLoading();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    dateChoose(item) {
      this.selectedData.from_date = moment(item, "YYYY-MM-DD ddd").format(
        "YYYY-MM-DD"
      );
      this.searchAvailable();
    },
    timeChoose() {
      this.searchAvailable();
    },
    submit() {
      this.startLoading();
      let user_token = this.loggedIn
        ? cookies.get("user_token")
        : cookies.get("guest");
      let payload = {
        business_id: this.selectedData.business_id || cookies.get("BusId"),
        api_token: user_token,
        attenders: Object.values(this.selectedData.attenders),
        hall_id: this.selectedData.hall_id,
        persons: this.selectedData.attendeesNO,
        on_date: this.selectedData.from_date,
        total_amount: this.payObj.price,
      };
      if (this.spent_time && this.spent_time != null) {
        payload = {
          ...payload,
          from: this.selectedData.time,
        };
      } else {
        payload = {
          ...payload,
          time_id: this.selectedData.service_id,
        };
      }
      this.validateReserveData(payload)
        .then((res) => {
          this.reservationTimeID(payload)
            .then((res) => {
              this.showMsg({
                text: this.$t("ReservationSuccessfulRedirect"),
                type: "success",
              });
              let secretKey = this.createReserveKey(
                res.data.data.created_at,
                res.data.data.id,
                this.user.id,
                this.business.id
              );
              this.stopLoading();
              if (this.$route.name == "CheckOut") {
                if (this.payObj.price) {
                  this.updateReservePay({
                    reservation_id: res.data.data.id,
                    api_token: cookies.get("user_token"),
                    secret_word: secretKey,
                    status: -1,
                    total_paid: this.selectedData.total_paid,
                    method: 1,
                    // transaction_id: this.transaction_id,
                  });
                }
              } else if (this.payObj.price) {
                setTimeout(() => {
                  this.$router.push({
                    name: "Payment",
                    params: {
                      total: this.selectedData.total_paid,
                      client_id: this.business.paypal_client_id,
                      client_secret: this.business.paypal_secret,
                      reservation_id: res.data.data.id,
                      secret_word: secretKey,
                    },
                  });
                }, 2000);
              } else {
                // if(this.orderParam) {
                //   console.log(this.orderParam);
                //   this.attackOrderToReservation({
                //     api_token: cookies.get('user_token') || cookies.get('guest'),
                //     order_id: this.orderParam,
                //     reservation_id: res.data.data.id,
                //   }).then(res => {
                //     this.showMsg({
                //       text: this.$t('OrderAttachSuccessful'),
                //       type: 'success'
                //     })
                //   }).catch(err => {
                //     this.showMsg({
                //       text: this.$t('OrderAttachError'),
                //       type: 'error'
                //     })
                //   })
                // }
                if (this.isVerified == true) {
                  this.$router.push({
                    name: "ProfileAppintments",
                    params: {
                      reservation_id: res.data.data.id,
                    },
                  });
                } else {
                  this.$router.push({
                    name: "Tickets",
                    params: {
                      reservation: res.data.data,
                    },
                  });
                }
              }
            })
            .catch((err) => {
              console.log(
                "🚀 ~ file: Reservation.vue ~ line 823 ~ .then ~ err",
                err
              );
              this.showMsg({
                text: this.$t("ReservationError"),
                type: "error",
              });
              this.stopLoading();
            });
        })
        .catch((err) => {
          this.showMsg({
            text: err,
            type: "error",
          });
          this.stopLoading();
        });
      this.stopLoading();
    },
    validateData(col, key) {
      let val = this.selectedForm[key];
      if (col && col.error) col.error = null;
      if (col && col.valid) delete col.valid;
      switch (col.type.toLowerCase()) {
        case "tel":
          if (!empty(val)) {
            col.loading = true;
            this.$forceUpdate();
            this.checkPhone(val)
              .then((res) => {
                if (res.data.data == true) {
                  col.valid = true;
                  this.$forceUpdate();
                } else {
                  $("#col_" + key).select();
                  col.error = this.$t("notVaildNumber");
                  this.$forceUpdate();
                }
              })
              .catch((err) => {
                $("#col_" + key).select();
                col.error = this.$t("notVaildNumber");
                this.$forceUpdate();
              });
          } else this.$forceUpdate();

          break;
        default:
          this.$forceUpdate();
      }
    },
    validateReserveData() {
      return new Promise((resolve, reject) => {
        if (
          (!this.selectedData.service_id ||
            this.selectedData.service_id == "") &&
          (!this.selectedData.time || this.selectedData.service_itime == "")
        ) {
          reject(this.$t("serviceAndTimeMissing"));
        } else if (
          !this.selectedData.hall_id ||
          this.selectedData.hall_id == ""
        ) {
          reject(this.$t("hallIDMissing"));
        } else if (
          !this.selectedData.from_date ||
          this.selectedData.from_date == ""
        ) {
          reject(this.$t("FromDateMissing"));
        } else if (
          this.spent_time &&
          this.spent_time != null &&
          (!this.selectedData.time || this.selectedData.time == "")
        ) {
          reject(this.$t("TimeMissing"));
        } else if (
          !this.selectedData.attendeesNO ||
          this.selectedData.attendeesNO < 1 ||
          this.selectedData.attendeesNO > this.maxAvailableBlocks
        ) {
          reject(this.$t("AttendeesNoMissing"));
        } else if (
          this.business.categories_ids.includes(14) &&
          this.selectedData.attendeesNO >
          Object.values(this.selectedData.attenders).length
        ) {
          reject(this.$t("AttendeesDataMissing"));
        } else if (
          this.payObj.price &&
          this.payObj.price != null &&
          (!this.selectedData.total_paid ||
            this.selectedData.total_paid < this.payObj.deal_pay ||
            this.selectedData.total_paid > this.payObj.price)
        ) {
          reject(this.$t("PaymentError"));
        } else resolve(this.$t("validationOK"));
      });
    },
    attenderForm(id) {
      if (!id) {
        this.selectedForm = {
          area: "",
          name: "",
          email: "",
          phone: "",
          street: "",
          postalcode: "",
          houseNumber: "",
        };
        id = Date.now();
      } else
        this.selectedForm = this.$root.$copy(this.selectedData.attenders[id]);
      this.selectedFormId = id;
      if (!this.firstOne) this.firstOne = id;
      this.$forceUpdate();
    },
    removeAttender(id) {
      delete this.selectedData.attenders[id];
      this.$forceUpdate();
    },
    saveAttends() {
      this.selectedData.attenders[this.selectedFormId] = {
        ...this.selectedForm,
      };
      this.cancelAttender();
      this.$forceUpdate();
    },
    cancelAttender() {
      this.selectedForm = null;
      this.selectedFormId = null;
      this.$forceUpdate();
    },
    closeModal() {
      $("#termsCond").modal("hide");
    },
    openModal() {
      $("#termsCond").modal("show");
    },
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      profileLoading: "profileLoading",
      user: "user",
      availableBlocks: "availableBlocks",
      bookDetails: "bookDetails",
      services: "services",
      business: "business",
      width: "width",
      isVerified: "isVerified",
      loggedIn: "isVerified",
      userAddresses: "userAddresses",
    }),
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    orderParam() {
      return this.$route.params.orderId ? this.$route.params.orderId : false;
    },
    maxAvailableBlocks() {
      return this.availableBlocks > 5 ? 5 : this.availableBlocks;
    },
  },
};
</script>
