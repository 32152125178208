<template>
  <div :class="t_ar" class="">
    <div
      class=" my-3 px-3 pointer shadow-eff date-body align-self-center"
      :class="[chosen == item.split(' ')[0] ? 'serv-body-chosen' : '']"
      @click='dateClicked(item)'
    >
      <div 
        class="readmore privacy-color" 
        :class="width == 'sm'? 'data-label' : 'opt-name'">
        {{arDays(item.split(' ')[1])}}
      </div>
      <div 
        class="text-ellipsis privacy-color"
        :class="width == 'sm'? 'smaller-get' : ''"  
      >
        {{item.split(' ')[0]}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {

  props: ['dateChoose', 'item', 'chosen'],
  mounted() {
    
  },
  methods: {
    ...mapActions(['getAvailableBlocks']),
    dateClicked(item) {
      this.dateChoose(item);
    },
    arDays(day) {
      switch (day) {
        case 'Sun':
          return this.$t('Sunday') 
        case 'Mon':
          return this.$t('Monday') 
        case 'Tue':
          return this.$t('Tuesday') 
        case 'Wed':
          return this.$t('Wednesday') 
        case 'Thu':
          return this.$t('Thursday') 
        case 'Fri':
          return this.$t('Friday') 
        case 'Sat':
          return this.$t('Saturday') 
        default:
          return day
      }
    }
  },
  computed: {
    ...mapGetters(['width']),
    ar() {
      return this.$i18n.locale == 'ar'
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse': '';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
  }
}
</script>