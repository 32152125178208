<template>
  <div class="">
    <div class="data-label p-2" :class="[t_ar,]">
      {{$t('leastAmount', {min: payObj.deal_pay, max: payObj.price})}}
    </div>
    <form @submit.prevent='giveUserPay'>
      <div class="row my-3 mb-md-0 justify-content-center" :class="flex_ar">
        <div class="w-75 input-group px-2 px-xl-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text bg-white"><i class="fa fa-money text-muted"></i></span>
            </div> -->
            <input 
              type='number'
              :class="t_ar"
              class="form-control text-input-sm border"
              v-model='total_paid'
              :min='payObj.deal_pay'
              :max='payObj.price'
              :placeholder="$t('paid')"
              @change='giveUserPay'
            />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      total_paid: this.payObj.deal_pay,
    }
  },
  watch: {
    payObj: function(newMin, oldMin) {
      this.total_paid = this.payObj.deal_pay;
    }
  },
  mounted() {
    this.total_paid = this.payObj.deal_pay;
    this.getUserPay(this.total_paid);
  },
  props: ['getUserPay', 'payObj'],
  methods: {
    ...mapMutations(['showMsg']),
    giveUserPay() {
      if (this.payObj.deal_pay > this.total_paid) {
        this.total_paid = this.payObj.deal_pay;
        this.showMsg({
          text: this.$t("EnterValidInitialPay", {max: this.payObj.price, min: this.payObj.deal_pay}),
          type: "error",
        });
      }else if (this.total_paid > this.payObj.price) {
        this.total_paid = this.payObj.price;
        this.showMsg({
          text: this.$t("EnterValidInitialPay", {max: this.payObj.price, min: this.payObj.deal_pay}),
          type: "error",
        });
      }
      this.getUserPay(this.total_paid);
    }
  },
  computed: {
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse': '';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
  }
}
</script>